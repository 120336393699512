@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-Italic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-Light.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-LightItalic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-Italic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins';
  src: local('MyFont'), url(../fonts/Poppins-BoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-SemiBold.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-SemiBoldItalic.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-Regular.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-Italic.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-BoldItalic.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-ExtraBold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'PlayfairDisplay';
  src: local('MyFont'), url(../fonts/PlayfairDisplay-ExtraBoldItalic.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}