.csew-pointer {
    cursor: pointer;
}

.csew-center-text {
    text-align: center;
}

.csew-items-margin-top > * {
    margin-top: 0.5rem;
}

.search-icon {
    cursor: pointer;
    color: var(--brand-yellow-color);
}

.search-icon:hover {
    color: var(--brand-yellow-color_hover);
}