@import './Variables.css';
@import './Fonts.css';

#root {
    position: relative !important;
}

body {
    font-family: 'Century Gothic', 'Poppins', 'PlayfairDisplay', sans-serif;
    color: var(--color-verian-black);
}

.csew-header-full {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
}

.csew-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    background-color: var(--color-white);
}
.csew-container {
    margin-top: 8rem;
    padding-bottom: 5rem;
}

.csew-container table {
    color: var(--color-verian-black);
}

.csew-container table a {
    color: var(--color-highlight-orange);
}

.csew-container tr {
    border-bottom-color: var(--color-verian-black) !important;
}

.csew-logo-name-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.csew-logo-wrapper img {
    width: 38px;
    height: 38px;
}

.csew-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
}

.csew-margin-left {
    margin-left: 0.5rem;
}

.csew-striped-list > .list-group-item:nth-child(even) {
    background-color: var(--color-pale-grey);
}

.csew-grey-brand-color {
    background-color: var(--color-light-grey);
}

.csew-logo-image {
    width: 124px;
    padding-bottom: 2px;
}

.csew-header__public-notice {
    margin-left: 28px;
    margin-bottom: 0;
    display: inline-block;
}

.csew-navbar .navbar-nav .nav-link {
    color: var(--color-verian-black);
}

.csew-container .nav-link {
    color: var(--color-verian-black);
}

.csew-navbar .navbar-nav .nav-link:hover {
    text-decoration: underline;
}

.csew-navbar .navbar-nav .nav-link.active {
    font-weight: 600;
}

.csew-button {
    background-color: var(--color-highlight-orange);
    border: 1px solid var(--color-highlight-orange);
    border-radius: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.68px;
    line-height: 20px;
    padding: 12px 24px 11px;
    text-decoration: none;
    text-transform: uppercase;
    color: var(--color-verian-black);
    transition: all .4s ease-in-out;
}

.csew-button:disabled {
    background-color: var(--color-deep-grey);
    border: 1px solid var(--color-white);
    color: var(--color-white);
}

.csew-button_small {
    font-size: 12px;
    font-weight: 400;
    padding: 10px 16px 9px;
}

.csew-button_secondary {
    border: 1px solid var(--color-highlight-teal);
    background-color: var(--color-highlight-teal);
}

.csew-button_tertiary {
    border: 1px solid var(--color-verian-black);
    background-color: var(--color-verian-black);
    color: var(--color-white);
}

.csew-button:hover:not(:disabled) {
    border: 1px solid var(--color-deep-grey);
    background-color: var(--color-deep-grey);
    color: var(--color-white);
}

.csew-button_secondary:hover {
    border: 1px solid var(--color-highlight-teal);
    background-color: var(--color-white);
    color: var(--color-verian-black);
}

.csew-button_tertiary:hover {
    border: 1px solid var(--color-deep-grey);
    background-color: var(--color-white);
    color: var(--color-verian-black);
}

.csew-user {
    display: flex;
    align-items: center;
    gap: 20px;
}

.csew-progress {
    background-color: var(--color-white);
    border: 1px solid var(--color-medium-grey);
}

.csew-progress .progress-bar {
    background-color: var(--color-highlight-orange);
}

.csew-card a, 
.csew-card path {
    font-weight: 600;
    color: var(--color-deep-grey);

}

.csew-card a:hover,
.csew-card a:hover path {
    color: var(--color-verian-black);
}

.csew-card .list-group-item,
.csew-card .csew-margin-left, 
.csew-collapse div {
    color: var(--color-verian-black);
}

.csew-date .input-group-text {
    border-radius: 0;
}

.csew-file {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.csew-form-group {
    color: var(--color-verian-black);
    font-size: 14px;
}

.csew-form-group .form-control,
.csew-form-group .input-group-text{
    color: var(--color-verian-black);
}

.csew-form_disabled {
    opacity: 0.5;
}

.csew-download-validation-error {
    display: inline-block;
    margin-top: 10px;
    padding: .25rem .5rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .375rem;
}
