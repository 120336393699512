:root {
  --color-white: #FFFFFF;
  --color-pale-grey: #F9EEE3;
  --color-light-grey: #D3C8BD;
  --color-medium-grey: #BAB0AB;
  --color-dark-grey:  #9C9090;
  --color-deep-grey:  #736868;
  --color-verian-black:   #282626;
  --color-highlight-orange: #FF7D04;
  --color-highlight-teal: #20BAAF;
  --color-border: #dee2e6;
}